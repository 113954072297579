import { Text } from "react-native";

import EStyleSheet from "react-native-extended-stylesheet";
import * as Linking from "expo-linking";
import { SafeAreaProvider, SafeAreaView } from "react-native-safe-area-context";
import { getColorPallet } from "./src/components/colors";

EStyleSheet.build({
    // always call EStyleSheet.build() even if you don't use global variables!
    $textColor: "#0275d8",
});

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

const prefix = Linking.createURL("/");

export default function App() {

    //
    return (
        <SafeAreaProvider>
            <SafeAreaView style={styles.container}>
                <Text style={styles.brand}>Connectionsly</Text>
                <Text style={styles.message}>Thank you for your support, but...</Text>
                <Text style={styles.message}>We've shutdown</Text>
                <Text style={styles.message}>And we're working on something new</Text>
                <Text style={styles.link}
                      onPress={() => Linking.openURL('http://calibrtr.com')}>
                    calibrtr.com
                </Text>
            </SafeAreaView>
        </SafeAreaProvider>
    );

    // stack view showing login screen first stored in screens/LoginScreen.js

    //
}

const styles =  EStyleSheet.create({

    container: {
        flex: 1,
        backgroundColor: getColorPallet().color5,
        color: "white",
        alignItems: 'center',
        justifyContent: 'center',
    },
    brand:{
        fontSize: "3.5rem",
        color: "white",
        fontWeight: "bold",
    },
    message:{
        fontSize: "2rem",
        color: "white",
        margin: "1rem",
    },
    link:{
        fontSize: "2rem",
        color: "blue",
        textDecorationLine: "underline",
    }
});