export const pallet1 = {
  color1: "#67D6E4",
  color2: "#7FCABD",
  color3: "#F0BC42",
  color4: "#E8553D",
  color5: "#D9344C",
};

export const pallet2 = {
  color1: "#67D6E4",
  color2: "#345995",
  color3: "#235789",
  color4: "#CB9CF2",
  color5: "#D9344C",
};

let currentPallet = pallet2;

export function setColorPallet(pallet) {
  currentPallet = pallet;
}

export function getColorPallet() {
  return currentPallet;
}
